*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.App{
    margin-top: 75px;
    box-shadow: inset 0 -300px #191919; 
}
.ReactModal__Overlay{
    z-index: 2;
}
/* The animation code */
@keyframes trace {
    from {
        stroke-dashoffset: var(--length);
    }
    to {
        stroke-dashoffset: 0;
    }
}
.nav-bar{
    background-color: rgba(255, 255, 255,.6);
    filter: drop-shadow(10px);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    top: 60px;
    position: sticky;
    z-index: 1;
    display: flex;
    width:30vw;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 14px 20px rgba(0,0,0, .2), 0 0 0 1px rgba(0, 0, 0,.1);
}

nav .logo{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
nav button{
    height:40px;
    border-radius: 20px;
    padding:10px;
    background-image: linear-gradient(to right, #ff7d1e, #fd40e5);
    color:white;
    border: none;
}
.company-name{
    width:100px;
}
section{
    height: 100vh;
}

.hero-page{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;

}
.hero-text{
    top:100px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows : repeat(3, 1fr);
    justify-items: center;
    align-items: center;
}
.first-line, .third-line{
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(131deg, #ff7d1e 36%, #fd40e5);
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 5rem;
}
.third-line{
    margin-left: 40px;
}
.wrapper {
    height: 200vh; 
}
.box {
    top:0;
    position: sticky;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.short-box {
    height: 600px;
    width: 1000px;
    background-color: #191919;
    transform: scale(0.5);
    z-index: 2;
    border-radius: 10px;
}
.inner-box{
    height: 100%;
    display: grid;
    grid-template-columns: .7fr repeat(2, 1fr);
  grid-template-rows: .5fr repeat(2, 1fr);
    grid-template-areas: 
    'sidebar nav nav nav'
    'sidebar graph graph graph'
    'sidebar graph graph graph'
    ;
}
.inner-sidebar{
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
 
    font-size: 0.7rem;
    padding: 10px;
    color: #fff;
}
.inner-sidebar img{
    height:50px;
    width: 100px;
}
.inner-sidebar p{
    padding: 30px;
    font-size: 1rem;
    border-radius: 16px;
    background:linear-gradient(to right, #ff7d1e, #fd40e5);
}
.inner-nav{
    grid-area: nav ; 
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner-nav nav{
    border-radius: 16px;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source:linear-gradient(to right, #ff7d1e, #fd40e5);;
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.inner-nav p{
    padding:10px;
}
.inner-nav img{
    height: 30px;
    width: 30px;
}
.inner-graph{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: graph
}
.inner-graph .graph{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    height: 90%;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source:linear-gradient(to right, #ff7d1e, #fd40e5);;
    width: 90%;
}


.services{
    height:100%;
    background-color: #191919;
    width:1000px;
    margin:0 auto;
    overflow: hidden;
    border-radius: 1rem;
}
.floater{
    position:absolute;
    right: 0;
    /* animation: rotate 5s linear 2s infinite forwards; */
    transition: transform 1 ease-in-out;
    z-index: -1;
}
@keyframes rotate {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
.card-holder{
    display: flex;
    justify-content: space-around;
    width: 100vw;
    padding: 100px;
}

.card-wrapper{
    transform: translateX(-150px);
    display: inline-block;
    width: 400px;
    padding-bottom: 50px;
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    gap:10px;
}
.card-wrapper p{
 text-align: left;
 margin: 40px;
}
.card-wrapper img{
    height: 300px;
    width: 400px;
    border-radius: 16px;
}
.services-text{
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
}
.services-text p{
    text-align: center;
    font-weight: bold;
}
.services-text button{
    background-image: linear-gradient(to right, #ff7d1e, #fd40e5);;
    padding:10px;
    border-radius: 20px;
    color: white;
}
.terms-and-conditions{
    position: relative;
    background-image: linear-gradient(to right, #ff7d1e, #fd40e5);
    width: 50vw;
    margin: 100px auto;
    padding: 50px;
    border-radius:16px;
    color: white;
    margin-bottom: 0;
    bottom: 70px;
    overflow-x:visible;
}
.bf{
    position: absolute;
    /* animation: rotate 5s linear 2s infinite forwards; */
    transition: transform 1 ease-in-out;
    z-index: -1;
}

.terms-and-conditions {
    position: relative;
    background-image: linear-gradient(to right, #ff7d1e, #fd40e5);
    margin: 100px auto;
    padding: 50px;
    border-radius: 16px;
    color: white;
    margin-bottom: 0;
    bottom: 70px;
    max-width: 1200px;
}

.check-container {
    display: flex;
    align-items: center;
    margin: 10px;
}
.check-container .todo-para{
    margin:10px;
}
.email-container .todo-email-input{
    padding:10px;
    border-radius: 20px 0 0 20px;
    display: inline;
    width: 60%;
}
.todo-button{
    padding: 10px;
    border-radius: 0 20px 20px 0;
    background:black;
    color: white;
    display: inline;
    width:20%;
}
.todo-button.disabled{
    background-color: grey;   
}

/* The code below is for modal */
.cont-box{
    display: flex;
    flex-direction: row;
}
.header-box{
    height: 40px;
    font-size: x-large;
    font-weight: bold;
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #dee2e6;
}
#bc{
    font-size: large;
    font-family: sans-serif;
    font-weight: 300;
    height: 40px;
    width:40px;
    border-radius: 20px;
    background-image: linear-gradient(to right, #ff7d1e, #fd40e5);
    color: white;
    border: none;
}
.info-container{
    align-items: center;
    display: flex;
    flex-direction: row;
    padding:1rem;
}

.info-container svg{
    height: 30px;
    width: 50px;
}
.info-container div{
    margin-left: 20px;
}
.tspawn{
    font-size: larger;
    font-weight: 500;
}

svg {
    max-width: 100%;
    height: auto;
}
.startsvg{
    position: absolute;
}

/*the below code is for mobile view*/
@media (max-width:768px){
    /*This is for nav bar*/
    .nav-bar {
        width: 43%;
    }
    .nav-bar .logo{
        margin-left: 10px;
    }

    .nav-bar button {
        height: 30px;
        padding: 5px;
        font-size: 12px;
    }
     /*This is for text at the start */
    .hero-text {
        top: 40px;
        grid-template-columns: 135px;
        grid-template-rows: 40px;
    }
    .first-line{
        margin-right: 160px;
    }
    .second-line{
        font-size: 16px;
        margin-top: 20px;
        margin-right: 100px;
    }
    .first-line,
    .third-line {
        font-size: 40px;
    }
    .third-line{
        margin-top: 10px;
        margin-left: 120px;
    }
    /* This is Box Part */
    .wrapper {
        height: 100vh;
    }

    .box {
        top: 0;
        position: sticky;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .short-box {
        background-color: #191919;
        transform: scale(1);
        z-index: 2;
    }

    .inner-box {
        height: 100%;
        min-width: min-content;
        display: grid;
        grid-template-columns: .5fr 1fr 1fr;
        grid-template-rows: .5fr repeat(2, 1fr);
        grid-template-areas:
            'sidebar nav nav nav'
            'sidebar graph graph graph'
            'sidebar graph graph graph'
        ;
    }

    .inner-sidebar {
        grid-area: sidebar;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: 0.7rem;
        padding: 1px;
        color: #fff;
    }

    .inner-sidebar img {
        height: 50px;
    }

    .inner-sidebar p {
        padding: 10px;
        font-size: inherit;
        border-radius: 16px;
        background: linear-gradient(to right, #ff7d1e, #fd40e5);
    }

    .inner-nav {
        grid-area: nav;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .inner-nav nav {
        width: 90%;
        min-width: min-content;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .inner-nav p {
        padding: 10px;
    }

    .inner-nav img {
        height: 30px;
        width: 30px;
    }

    .inner-graph {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area: graph
    }

    .inner-graph .graph {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90%;
        width: 90%;
        padding: 10px;
    }
    /* This is for services box */
    .card-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .card-wrapper {
        transform: none;
        width: 90%;
        max-width: 400px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 1rem;
        text-align: center;
        padding: 10px;
    }

    .card-wrapper p {
        text-align: center;
        margin: 10px;
    }

    .card-wrapper img {
        height: auto;
        width: 100%;
        border-radius: 16px;
    }
    .services svg{
        margin-right: -40px;
    }
    .services{
        width:90%;
        max-width: none;
        margin:10px auto;
    }

    .services-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    .services-text img {
        height: 100px;
        width: auto;
        margin-bottom: 10px;
    }

    .services-text p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .services-text a {
        margin-top: 10px;
    }

    .services-text button {
        padding: 10px 20px;
        font-size: 14px;
    }

    /*This is for terms and conditions */
    .terms-and-conditions {
        width: 95%;
        padding: 30px;
    }

    .accept-tandc {
        margin-top: 15px;
    }

    .email-submit {
        width: 95%;
    }

    #e1 {
        width: 100%;
    }

    /*This is for Modal part*/
    .cont-box {
        width: 50%;
        max-width: none;
        height: auto;
        margin: 10px auto;
        padding: 10px;
    }
    .header-box {
        font-size: medium;
        margin-bottom: 5px;
        padding-bottom: 5px;
    }

    #bc {
        font-size: small;
        height: 20px;
        width: 20px;
        border-radius: 10px;
    }

    .info-container {
        align-items: center;
        flex-direction: row;
    }

    .info-container svg {
        height: 20px;
        width: 30px;
    }

    .info-container div {
        margin-top: 0;
        margin-left: 10px;
    }
    .tspawn {
        font-size: small;
    }
}